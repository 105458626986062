<template>
  <div>
    <SubHeader :pathList="pathList" :title="title" />

    <div id="contents" class="contents">
      <div class="title-sort-box">
        <h3>{{ $t('notice.detail-title') }}</h3>
      </div>
      <!--/title-sort-box-->
      <article class="board-view">
        <div class="view-head">
          <div class="view-head-left">
            <h4>{{ detailData.noticeSj }}</h4>
          </div>
          <div class="view-head-right">
            <dl>
              <dd>
                <span>{{ $t('notice.detail-label-date') }}</span
                ><b>{{ common_getDateString(detailData.creatDt) }}</b>
              </dd>
              <dd>
                <span>{{ $t('notice.detail-label-view') }}</span
                ><b>{{ detailData.rdcnt }}</b>
              </dd>
              <dd>
                <span class="bold">{{
                  $t('notice.detail-label-download')
                }}</span
                ><span
                  v-html="
                    common_getFile(detailData.fileNm, detailData.noticeNo) ||
                      '-'
                  "
                ></span>
              </dd>
            </dl>
          </div>
        </div>
        <div class="view-body" v-html="replaceCn(detailData.noticeCn)"></div>
      </article>

      <div class="btns-bottom">
        <button type="button" class="bt-md bt-default" @click="goBack()">
          <span>{{ $t('common.btn-back') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchNoticeDetail } from '@/api/noticeApi.js';
import CommonMixins from '@/mixins/CommonMixins.js';
export default {
  mixins: [CommonMixins],

  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.about'),
        this.$i18n.t('subMenu.notice'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.about'),
        smallTitle: this.$i18n.t('menu.about-eng'),
      },
      detailData: {},
    };
  },
  created() {
    this.fetchNoticeDetail();
  },
  methods: {
    // 공지사항 상세 정보 가져오기
    async fetchNoticeDetail() {
      this.$dialogs.loading();
      const { noticeNo } = this.$route.params;
      const {
        data: { detail },
      } = await fetchNoticeDetail(noticeNo).catch(() => {
        this.$dialogs.close();
      });
      this.detailData = detail;
      this.$dialogs.close();
    },
    // 목록으로
    goBack() {
      const { page } = this.$route.params;
      this.$router.push({ name: 'notice', params: { page } });
    },
  },
};
</script>
<style></style>
