import { notice } from './index.js';

function fetchNoticeList(params) {
  return notice.post(`/list`, params);
}

function fetchNoticeDetail(noticeNo) {
  return notice.post(`/detail`, { noticeNo });
}

export { fetchNoticeList, fetchNoticeDetail };
